import React, { useState } from "react";
import { Button, Stack, Typography, useMediaQuery, useTheme, Rating, Backdrop, CircularProgress } from "@mui/material";
import "../../styles/nosotros.css";
import axios from "axios";
import { sendApiMail } from "../../../store/mailer/mailer";
import MessageDialog from "../componentsGlobal/MessageDialog";
import ReviewDialog from "./ReviewDialog";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const message = {
    title: "OPINIÓN ENVIADA con éxito",
    icon: "/content/img/SC/check.svg",
    description: "Tu opinión es muy importante para nosotros y nos ayuda a seguir ofreciendo el mejor servicio posible.",
};

const Nosotros = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil
    const [expanded, setExpanded] = useState(false);

    const [nombre, setNombre] = useState("");
    const [correo, setCorreo] = useState("");
    const [asunto, setAsunto] = useState("");
    const [comentario, setComentario] = useState("");
    const [telefono, setTelefono] = useState("");
    const [ratingCompraM, setRatingCompraM] = useState(0);
    const [ratingWebM, setRatingWebM] = useState(0);
    const [enviado, setEnviado] = React.useState(false); // Estado para controlar si se envió el mensaje
    const [openDialog, setOpenDialog] = useState(false); // Estado para controlar la apertura del diálogo
    const [alertShow, setAlertShow] = useState(false);
    const [mensajeAlerta, setMensajeError] = useState("");
    const [loadingMail, setLoadingMail] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const validateEmail = (correo) => {
        // Expresión regular para validar el formato de correo electrónico
        // Esta expresión regular es básica y no cubre todos los casos posibles
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(correo);
    };

    const handleSubmit = async (tipoFormulario) => {
        if (tipoFormulario === "contacts") {
            if (!nombre || nombre.trim() === "" || !correo || correo.trim() === "" || !asunto || asunto.trim() === "" || !comentario || comentario.trim() === "") {
                setAlertShow(true);
                setMensajeError("Por favor completa todos los campos.");
                return;
            }
        }
        if (tipoFormulario === "reviews") {
            if (isNaN(telefono)) {
                setAlertShow(true);
                setMensajeError("El teléfono debe contener sólo números.");
                return;
            }
            const longitudTelefono = telefono.length;
            if (longitudTelefono < 8 || longitudTelefono > 11) {
                setAlertShow(true);
                setMensajeError("El teléfono debe tener entre 8 y 11 caracteres.");
                return;
            }
            if (!nombre || nombre.trim() === "" || !correo || correo.trim() === "" || !telefono || telefono.trim() === "" || ratingCompraM === 0 || ratingWebM === 0 || !comentario || comentario.trim() === "") {
                setAlertShow(true);
                setMensajeError("Por favor completa todos los campos.");
                return;
            }
        }
        if (!validateEmail(correo)) {
            setAlertShow(true);
            setMensajeError("Por favor ingresa un correo válido.");

            return;
        }

        const requestData = {
            nombre: nombre,
            correo: correo,
            asunto: asunto,
            telefono: telefono,
            ratingCompra: ratingCompraM,
            ratingWeb: ratingWebM,
            comentario: comentario,
        };
        setLoadingMail(true);

        const result = await sendApiMail({ data: requestData, type: tipoFormulario });

        if (result.ok === true) {
            setNombre("");
            setCorreo("");
            setTelefono("");
            setRatingCompraM(0);
            setRatingWebM(0);
            setAsunto("");
            setComentario("");
            setEnviado(true); // Establece enviado a true si el envío fue exitoso
            setAlertShow(false);
            setMensajeError("");
        } else {
            alert("Error al enviar el mensaje");
        }
        setLoadingMail(false);
        setTimeout(() => {
            setEnviado(false); // Establece enviado a true si el envío fue exitoso
        }, 4000); // Ejemplo de espera de 1 segundo antes de simular el resultado
    };

    const handleClickOpenDialog = () => {
        // Función para abrir el diálogo
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        // Función para cerrar el diálogo
        setOpenDialog(false);
    };

    return (
        <>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingMail}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack
                style={{
                    marginTop: isMobile ? "15%" : isTablet ? "7%" : "3%",
                    backgroundImage: "url(/content/img/SC/fondo-CP.svg)",
                    backgroundSize: "cover",
                    padding: isMobile ? "30px" : "50px",
                }}
            >
                {enviado && <MessageDialog title={message.title} icon={message.icon} description={message.description} />}
                <Typography className="tituloNosotros">NOSOTROS</Typography>
                <Stack
                    // spacing={isSurface ? 10 : 20}
                    justifyContent="space-between"
                    direction={isMobile || isTablet ? "column" : "row"}
                    style={{
                        border: "1px solid rgba(255, 255, 255, 0.20)",
                        backgroundColor: "rgba(0, 0, 0, 0.24)",
                        borderRadius: "20px",
                        padding: isMobile ? "2%" : isSurface ? "1%" : "3%",
                        paddingTop: "2%",
                        paddingRight: isMobile ? "2%" : isSurface ? "1%" : "10%",
                    }}
                >
                    <Stack>
                        <Typography className="text1Nosotros">¡Viví la magia de las historias!</Typography>
                        <Typography className="text2Nosotros">
                            Studio Cinemas, tu cine boutique favorito ubicado
                            <br />
                            en City Place Santa Ana.
                        </Typography>
                        <Typography className="text2Nosotros">
                            Nuestra misión es que vivás el cine al máximo,
                            <br />
                            que tengás una experiencia única y que venir <br />
                            al cine sea tu actividad favorita.
                        </Typography>
                        <br />
                        <Typography className="text3Nosotros" style={{ marginBottom: "2%" }}>
                            Podés contactarnos por el medio de tu preferencia, estamos atentos <br />
                            para responder tus consultas y comentarios a la mayor brevedad.
                        </Typography>
                        <Stack direction="row" spacing={isMobile || isTablet ? 0.5 : 1}>
                            <Typography className="text3Nosotros">Central Telefónica:</Typography>
                            <Typography className="text3NosotrosBold">4035 3105</Typography>
                        </Stack>
                        <Stack direction="row" spacing={isMobile || isTablet ? 0.5 : 1}>
                            <Typography className="text3Nosotros">Correo:</Typography>
                            <Typography className="text3NosotrosBold">info@studiocinemascr.com</Typography>
                        </Stack>
                        <Stack direction="row" spacing={isMobile || isTablet ? 0.5 : 1} style={{ marginBottom: "2%" }}>
                            <Typography className="text3Nosotros">Horario de atención:</Typography>
                            <Typography className="text3NosotrosBold">Lunes a Domingo de 12:30md a 10:00pm</Typography>
                        </Stack>
                        <Stack direction="row" spacing={isMobile || isTablet ? 0 : 1}>
                            <Typography className="text3Nosotros">Razón Social:</Typography>
                            <Typography className="text3NosotrosBold">Studio Cinemas Entretenimiento Sociedad Anónima</Typography>
                        </Stack>
                        <Stack direction="row" spacing={isMobile || isTablet ? 0.5 : 1}>
                            <Typography className="text3Nosotros">Cédula Jurídica:</Typography>
                            <Typography className="text3NosotrosBold">3-101-688870</Typography>
                        </Stack>
                        <Stack direction="row" spacing={isMobile || isTablet ? 0.5 : 1}>
                            <Typography className="text3Nosotros">Correo:</Typography>
                            <Typography className="text3NosotrosBold">info@studiocinemascr.com</Typography>
                        </Stack>
                        <Stack direction="row" spacing={isMobile || isTablet ? 0.5 : 1}>
                            <Typography className="text3Nosotros">Teléfono:</Typography>
                            <Typography className="text3NosotrosBold">+(506) 4035 3105</Typography>
                        </Stack>
                        <Stack direction="row" spacing={isMobile || isTablet ? 0.5 : 1}>
                            <Typography className="text3Nosotros" style={{ minWidth: "106px" }}>
                                Dirección Exacta:
                            </Typography>
                            <Typography className="text3NosotrosBold">City Place Santa Ana, Edificio A, 2 piso. Santa Ana, San José, Costa Rica.</Typography>
                        </Stack>
                        <Typography className="text3NosotrosBold">Desarrollado y operado por Rocca Portafolio Comercial</Typography>

                        <Stack direction="row" spacing={1} style={{ marginTop: "4%" }}>
                            <Button variant="contained" className="btnCalifica" onClick={handleClickOpenDialog}>
                                CALIFICA NUESTRO SERVICIO
                            </Button>
                            <ReviewDialog open={openDialog} handleClose={handleCloseDialog} />
                            <a href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x8fa0feabded40273:0x504ef7c4472421ea!12e1?source=g.page.m.ia._&laa=nmx-review-solicitation-ia2" className="btnGoogle">
                                <img src="/content/img/SC/img-icon.svg" width={"15%"} />
                                GOOGLE REVIEWS
                            </a>
                        </Stack>
                    </Stack>

                    <Stack style={{ display: isMobile || isTablet ? "flex" : "none", marginTop: "5%" }}>
                        <Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                            style={{
                                backgroundColor: "transparent",
                                borderTop: "1px solid #727272",
                                borderRadius: "0%",
                                width: "100%",
                            }}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                            <Stack alignItems="center" style={{width: "100%"}}>
                            <Typography
                                    sx={{
                                        color: "white",
                                        fontFamily: "FuturaP-Bold",
                                        fontSize: "15px",
                                    }}
                                >
                                    CALIFICA NUESTRO SERVICIO
                                </Typography>
                            </Stack>
                                
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack spacing={2}>
                                    <Typography
                                        sx={{
                                            color: "#C7C7C7",
                                            fontFamily: "FuturaP-ExtraBold",
                                            fontSize: "14px",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        OPINIÓN DE USUARIO
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#fff",
                                            fontFamily: "FuturaP-ExtraBold",
                                            fontSize: "10px",
                                        }}
                                    >
                                        En caso necesitemos contactarte de vuelta, te agradecemos la siguiente información
                                    </Typography>
                                    <Stack direction="column" spacing={0}>
                                        <Typography
                                            style={{
                                                color: "#C7C7C7",
                                                fontFamily: "FuturaP-Bold",
                                                fontSize: isMobile ? "13px" : "16px",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            NOMBRE
                                        </Typography>
                                        <input className="inputReview" type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                                    </Stack>
                                    <Stack direction="column" spacing={0}>
                                        <Typography
                                            style={{
                                                color: "#C7C7C7",
                                                fontFamily: "FuturaP-Bold",
                                                fontSize: isMobile ? "13px" : "16px",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            CORREO ELECTRÓNICO
                                        </Typography>
                                        <input className="inputReview" type="text" value={correo} onChange={(e) => setCorreo(e.target.value)} />
                                    </Stack>
                                    <Stack direction="column" spacing={0}>
                                        <Typography
                                            style={{
                                                color: "#C7C7C7",
                                                fontFamily: "FuturaP-Bold",
                                                fontSize: isMobile ? "15px" : "16px",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            TELÉFONO
                                        </Typography>
                                        <input className="inputReview" type="text" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                                    </Stack>
                                    <Stack direction="column" spacing={0}>
                                        <Typography
                                            style={{
                                                color: "#C7C7C7",
                                                fontFamily: "FuturaP-Bold",
                                                fontSize: isMobile ? "15px" : "16px",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            ¿Cómo calificas nuestro proceso de compra en línea?
                                        </Typography>
                                        <Rating name="size-medium" defaultValue={0} value={ratingCompraM} onChange={(e, newValue) => setRatingCompraM(newValue)} />
                                    </Stack>
                                    <Stack direction="column" spacing={0}>
                                        <Typography
                                            style={{
                                                color: "#C7C7C7",
                                                fontFamily: "FuturaP-Bold",
                                                fontSize: isMobile ? "15px" : "16px",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            ¿Qué calificación general le darías a Studio Cinemas?
                                        </Typography>
                                        <Rating name="size-medium" defaultValue={0} value={ratingWebM} onChange={(e, newValue) => setRatingWebM(newValue)} />
                                    </Stack>
                                    <Stack direction="column" spacing={1}>
                                        <Stack spacing={0}>
                                            <Typography
                                                style={{
                                                    color: "#C7C7C7",
                                                    fontFamily: "FuturaP-Bold",
                                                    fontSize: isMobile ? "13px" : "16px",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                Dudas, consultas, comentarios...
                                            </Typography>
                                            <Typography
                                                style={{
                                                    color: "#C7C7C7",
                                                    fontFamily: "FuturaP-Bold",
                                                    fontSize: isMobile ? "10px" : "11px",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                Nos encanta recibir tus mensajes, esto nos permite estar en constante mejora. ¡Gracias!
                                            </Typography>
                                        </Stack>
                                        <textarea className="textAreaReview scrollable-content" rows="4" cols="50" value={comentario} onChange={(e) => setComentario(e.target.value)}></textarea>
                                    </Stack>
                                    <Stack alignItems="center">
                                    <Button variant="contained" disabled={loadingMail} className="btnReview" onClick={() => handleSubmit("reviews")}>
                                        ENVIAR
                                    </Button>

                                    </Stack>
                                   
                                </Stack>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={expanded === "panel2"}
                            onChange={handleChange("panel2")}
                            style={{
                                backgroundColor: "transparent",
                                borderTop: "1px solid #727272",

                                borderRadius: "0%",
                                width: "100%",
                            }}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                            <Stack alignItems="center" style={{width: "100%"}}>
                                <Typography
                                    sx={{
                                        color: "white",
                                        fontFamily: "FuturaP-Bold",
                                        fontSize: "15px",
                                    }}
                                >
                                    CONTACTO
                                </Typography>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack spacing={2} justifyContent="center" alignItems="center">
                                    <Stack direction="column" spacing={1}>
                                        <Typography
                                            style={{
                                                color: "#C7C7C7",
                                                fontFamily: "FuturaP-Bold",
                                                fontSize: isMobile ? "13px" : "16px",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            NOMBRE *
                                        </Typography>
                                        <input className="inputForm" type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                                    </Stack>

                                    <Stack direction="column" spacing={0}>
                                        <Typography
                                            style={{
                                                color: "#C7C7C7",
                                                fontFamily: "FuturaP-Bold",
                                                fontSize: isMobile ? "13px" : "16px",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            CORREO ELECTRÓNICO *
                                        </Typography>
                                        <input className="inputForm" type="email" value={correo} onChange={(e) => setCorreo(e.target.value)} />
                                    </Stack>

                                    <Stack direction="column" spacing={0}>
                                        <Typography
                                            style={{
                                                color: "#C7C7C7",
                                                fontFamily: "FuturaP-Bold",
                                                fontSize: isMobile ? "13px" : "16px",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            ASUNTO *
                                        </Typography>
                                        <input className="inputForm" type="text" value={asunto} onChange={(e) => setAsunto(e.target.value)} />
                                    </Stack>

                                    <Stack direction="column" spacing={0}>
                                        <Typography
                                            style={{
                                                color: "#C7C7C7",
                                                fontFamily: "FuturaP-Bold",
                                                fontSize: isMobile ? "13px" : "16px",
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            COMENTARIO *
                                        </Typography>
                                        <input className="inputForm" type="text" value={comentario} onChange={(e) => setComentario(e.target.value)} style={{ height: "154px" }} />
                                    </Stack>

                                    <Button variant="contained" disabled={loadingMail} className="btnEnviar" onClick={() => handleSubmit("contacts")}>
                                        ENVIAR
                                    </Button>
                                    <a href="https://wa.me/+50664884786" className="btnWhatsApp">
                                        WHATSAPP
                                    </a>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>

                    <Stack spacing={3} justifyContent="center" alignItems="center" style={{ display: isMobile || isTablet ? "none" : "flex" }}>
                        <Stack direction="column" spacing={1}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontFamily: "FuturaP-Bold",
                                    fontSize: isMobile ? "15px" : "16px",
                                    textTransform: "uppercase",
                                }}
                            >
                                NOMBRE *
                            </Typography>
                            <input className="inputForm" type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                        </Stack>

                        <Stack direction="column" spacing={1}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontFamily: "FuturaP-Bold",
                                    fontSize: isMobile ? "15px" : "16px",
                                    textTransform: "uppercase",
                                }}
                            >
                                CORREO ELECTRÓNICO *
                            </Typography>
                            <input className="inputForm" type="email" value={correo} onChange={(e) => setCorreo(e.target.value)} />
                        </Stack>

                        <Stack direction="column" spacing={1}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontFamily: "FuturaP-Bold",
                                    fontSize: isMobile ? "15px" : "16px",
                                    textTransform: "uppercase",
                                }}
                            >
                                ASUNTO *
                            </Typography>
                            <input className="inputForm" type="text" value={asunto} onChange={(e) => setAsunto(e.target.value)} />
                        </Stack>

                        <Stack direction="column" spacing={1}>
                            <Typography
                                style={{
                                    color: "#C7C7C7",
                                    fontFamily: "FuturaP-Bold",
                                    fontSize: isMobile ? "15px" : "16px",
                                    textTransform: "uppercase",
                                }}
                            >
                                COMENTARIO *
                            </Typography>
                            <textarea className="inputForm scrollable-content" rows="4" cols="50" value={comentario} onChange={(e) => setComentario(e.target.value)} style={{ height: "180px" }}></textarea>
                        </Stack>
                        {alertShow && <Typography style={{ fontSize: isMobile ? "10px" : "10", color: "#FF5733", fontFamily: "FuturaP-Bold", textTransform: "uppercase" }}>{mensajeAlerta}</Typography>}

                        <Button variant="contained" disabled={loadingMail} className="btnEnviar" onClick={() => handleSubmit("contacts")}>
                            ENVIAR
                        </Button>
                        <a href="https://wa.me/+50664884786" className="btnWhatsApp">
                            WHATSAPP
                        </a>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

export default Nosotros;
