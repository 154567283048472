import "../../../public/content/css/fonts-import.css";
import "../styles/index.css";
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Collapse, useMediaQuery, Stack, Button, Divider, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress"; // Importa CircularProgress desde el paquete MUI
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect, useState } from "react";
import { getTandasBd, setActiveMovieInfo, updatearrayInfoMovie } from "../../store/home";
import LoginDialog from "./componentsGlobal/LoginDialog";
import RegisterDialog from "./componentsGlobal/RegisterDialog";
import { startLogout } from "../../store/auth/thunks";

const Navbar = () => {
    const { step4 } = useSelector((state) => state.checkout);
    const { name_user, lasname_user } = useSelector((state) => state.auth);
    const statusRegister = useSelector((state) => state.auth.status);

    //Variables para manejo de usuarios
    const [openDialog, setOpenDialog] = useState(false); // Estado para controlar la apertura del diálogo de login
    const [openRegister, setOpenRegister] = useState(false); // Estado para controlar la apertura del diálogo

    // Importa los hooks y funciones necesarias desde React y react-router-dom
    const { allMoviesBillboard } = useSelector((state) => state.home);
    const navigate = useNavigate();

    /* Obtiene la función dispatch desde react-redux */
    const dispatch = useDispatch();

    //Funciones para manejo de modales de usuarios

    const handleClickOpenDialog = () => {
        // Función para abrir el diálogo Login
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        // Función para cerrar el diálogo login
        setOpenDialog(false);
    };

    const handleClickOpenRegister = () => {
        // Función para abrir el diálogo registro
        setOpenRegister(true);
    };

    const handleCloseRegister = () => {
        // Función para cerrar el diálogo registro
        setOpenRegister(false);
    };

    // Mapea todas las películas en cartelera para crear un menú de items
    const menuI = allMoviesBillboard.map((movie) => ({
        id: movie.idMasterMovie,
        name: movie.title,
    }));

    // Define los items del menú principal con subitems
    const menuItems = [
        {
            name: "Cartelera",
            subItems: [...menuI],
            route: "/cartelera",
        },
        {
            name: "Confitería",
            subItems: ["promocionales", "combos", "combos infantiles", "individuales"],
            route: "/confiteria",
        },
        {
            name: "Otros",
            subItems: ["nosotros", "califica el servicio", "contacto", "términos y condiciones", "políticas de privacidad"],
            route: "/nosotros",
        },
    ];

     const onLogout = () => {
         // Despacha la acción startLogout para iniciar el proceso de cierre de sesión
         dispatch(startLogout());
     };
    // Define los estados locales para la película aleatoria y el estado de carga de la imagen
    const [randomMovie, setRandomMovie] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    // Verificar si existen elementos en subItems después de slice(4)
    const hasSubItems = menuI.length > 4 ? true : false;

    // useEffect para cargar la primera película al iniciar el componente
    useEffect(() => {
        if (allMoviesBillboard.length > 0) {
            const firstMovie = allMoviesBillboard[0];
            // setRandomMovie(firstMovie);
            // setImageLoaded(false); // Resetea el estado de carga de la imagen
        }
    }, [allMoviesBillboard]);

    // useEffect para cambiar la película aleatoria cada 3 segundos
    useEffect(() => {
        const interval = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * allMoviesBillboard.length);
            const randomMovie = allMoviesBillboard[randomIndex];
            // setRandomMovie(randomMovie);
            // setImageLoaded(false); // Resetea el estado de carga de la imagen
        }, 3000); // Ejecutar cada 3 segundos

        return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
    }, [allMoviesBillboard]);

    // Función que maneja el evento de carga de la imagen
    const handleImageLoad = () => {
        setImageLoaded(true);
    };
    const [key, setKey] = React.useState(0);
    // Función que maneja el click del botón y navega a la película seleccionada
    const handleButtonClick = () => {
        //   if (randomMovie && randomMovie.idMasterMovie) {
        // Obtener la fecha actual en formato 'YYYY-MM-DD'
        const currentDate = new Date().toISOString().split("T")[0];

        // Elimina y establece el ID de la película aleatoria en localStorage
        localStorage.removeItem("idLocalMovie");
        localStorage.setItem("idLocalMovie",65);

        // Despacha acciones para actualizar la información de la película activa
        dispatch(setActiveMovieInfo(65));
        dispatch(updatearrayInfoMovie());

        // Despacha la acción para obtener tandas, pasando la fecha actual
        dispatch(getTandasBd(currentDate, 1));

        // Navega a la página de la película
        navigate("/movie");
        //  }
    };

    // Define los estados locales para la apertura del Drawer y el menú
    const [open, setOpen] = React.useState(false);
    const [openMenu, setOpenMenu] = React.useState(null);
    const isMobile = useMediaQuery("(max-width:600px)"); // Verifica si la pantalla es móvil
    const isTablet = useMediaQuery("(max-width:1024px)"); // Verifica si la pantalla es móvil
    const isSurface = useMediaQuery("(max-width:1370px)"); // Verifica si la pantalla es móvil

    // Función que maneja la apertura del Drawer
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    // Función que maneja el cierre del Drawer
    const handleDrawerClose = () => {
        setOpen(false);
    };

    // Función que maneja el click en los items del menú
    const handleClick = (menu) => {
        setOpenMenu((prevState) => (prevState === menu ? null : menu));
    };

    // Función que maneja la navegación y el logging de la película
    const getIdMovieInfo = (idMovie) => {
        // Obtener la fecha actual en formato 'YYYY-MM-DD'
        const currentDate = new Date().toISOString().split("T")[0];

        // Elimina y establece el ID de la película local
        localStorage.setItem("idLocalMovie", idMovie);

        // Despacha acciones para actualizar la información de la película activa
        dispatch(setActiveMovieInfo(idMovie));
        dispatch(updatearrayInfoMovie());

        // Despacha la acción para obtener tandas, pasando la fecha actual
        dispatch(getTandasBd(currentDate, 1));

        // Navega a la página de la película
        navigate("/movie");
    };
    const drawer = (
        <div style={{ width: "100%" }}>
            <List style={{ width: "100%" }}>
                {menuItems.map((menu, index) => (
                    <div key={index}>
                        <ListItem button onClick={() => handleClick(menu.name)} style={{ color: "#fff", fontFamily: "FuturaP-Bold", borderBottom: "1px solid #646464", width: "100%", display: "flex", justifyContent: "space-between", paddingTop: "5%", paddingBottom: "5%" }}>
                            <Typography style={{ color: "#fff", fontFamily: "FuturaP-Bold", fontSize: "19px", textTransform: "uppercase" }}>{menu.name}</Typography>
                            {openMenu === menu.name ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openMenu === menu.name} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{paddingTop:"2%"}}>
                                {menu.name === "Cartelera" && (
                                    <>
                                        <NavLink to="/cartelera?seccion=1" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "4%", textDecoration: "none", fontSize: "18px" }}>
                                        PELÍCULAS
                                        </NavLink>
                                        {/* <Typography style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "4%", fontSize: "18px" }}>cartelera</Typography> */}
                                        {menu.subItems.map((subItem, subIndex) => (
                                            <ListItem key={subIndex} button onClick={() => getIdMovieInfo(subItem.id)} sx={{ pl: 4 }}>
                                                <Typography style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", fontSize: "15px" }}>{subItem.name}</Typography>
                                            </ListItem>
                                        ))}
                                         <NavLink to="/cartelera?seccion=1" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "9%", textDecoration: "none", fontSize: "15px" }}>
                                                                VER MÁS...
                                                                </NavLink>
                                        <Typography style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "4%", fontSize: "18px" }}>DE INTERÉS</Typography>
                                        <Stack>
                                        <NavLink to="/cartelera" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "9%", textDecoration: "none", fontSize: "15px" }}>
                                                CARTELERA
                                            </NavLink>
                                            <NavLink to="/cartelera?seccion=2" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "9%", textDecoration: "none", fontSize: "15px" }}>
                                                PREVENTAS
                                            </NavLink>
                                            <NavLink to="/cartelera" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "9%", textDecoration: "none", fontSize: "15px" }}>
                                                PRÓXIMAMENTE
                                            </NavLink>
                                            <NavLink to="/confiteria" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "9%", textDecoration: "none", fontSize: "15px" }}>
                                                PROMOCIONALES
                                            </NavLink>
                                        </Stack>
                                        <br />
                                    </>
                                )}
                                {menu.name === "Confitería" && (
                                    <>
                                        <br />
                                        {step4.dataProducts
                                            .filter((category) => category.id === 111)
                                            .map((category) => (
                                                <React.Fragment key={category.id}>
                                                    <NavLink to="/confiteria" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "4%", fontSize: "18px", textDecoration: "none" }}>
                                                        {category.title}
                                                    </NavLink>
                                                    <Stack>
                                                        {category.products.map((product) => (
                                                            <NavLink key={product.id} to="/confiteria" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "9%", textDecoration: "none", fontSize: "15px" }}>
                                                                {product.name}
                                                            </NavLink>
                                                        ))}
                                                    </Stack>
                                                    <Stack>
                                                        <NavLink to="/confiteria" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "4%", textDecoration: "none", fontSize: "18px" }}>
                                                            combos
                                                        </NavLink>
                                                        <NavLink to="/confiteria" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "4%", textDecoration: "none", fontSize: "18px" }}>
                                                            combos infantiles
                                                        </NavLink>
                                                        <NavLink to="/confiteria" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "4%", textDecoration: "none", fontSize: "18px" }}>
                                                            individuales
                                                        </NavLink>
                                                    </Stack>
                                                </React.Fragment>
                                            ))}
                                        <br />
                                    </>
                                )}
                                {menu.name === "Otros" && (
                                    <>
                                        <Stack>
                                            <NavLink to="/nosotros" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "4%", textDecoration: "none", fontSize: "18px" }}>
                                                acerca de
                                            </NavLink>
                                            <NavLink to="/nosotros" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "9%", textDecoration: "none", fontSize: "15px" }}>
                                                NOSOTROS
                                            </NavLink>
                                            <NavLink to="/nosotros" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "9%", textDecoration: "none", fontSize: "15px" }}>
                                                CALIFICA EL SERVICIO
                                            </NavLink>
                                            <NavLink to="/nosotros" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "9%", textDecoration: "none", fontSize: "15px" }}>
                                                contacto
                                            </NavLink>
                                            <NavLink to="/legales" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "4%", textDecoration: "none", fontSize: "18px" }}>
                                                legal
                                            </NavLink>
                                            <NavLink to="/legales" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "9%", textDecoration: "none", fontSize: "15px" }}>
                                                POLÍTICAS DE PRIVACIDAD
                                            </NavLink>
                                            <NavLink to="/legales" style={{ color: "rgba(255, 255, 255, 0.70)", fontFamily: "FuturaP-Bold", textTransform: "uppercase", marginTop: "4%", marginLeft: "9%", textDecoration: "none", fontSize: "15px" }}>
                                                TÉRMINOS Y CONDICIONES
                                            </NavLink>
                                        </Stack>
                                        <br />
                                    </>
                                )}

                                {/* Otras secciones omitidas para mantener la claridad */}
                            </List>
                        </Collapse>
                    </div>
                ))}
            </List>
            <Stack alignItems={"flex-start"} style={{ paddingLeft: "2%" }}>
                {statusRegister === "authenticated" && (
                    <Button className="userOpcion">
                        {name_user} {lasname_user}
                    </Button>
                )}
                {statusRegister === "checking" && (
                    <>
                        <Button className="userOpcion" onClick={handleClickOpenRegister}>
                            Registro
                        </Button>

                        <Button className="userOpcion" onClick={handleClickOpenDialog}>
                            Iniciar Sesión
                        </Button>
                    </>
                )}
                <RegisterDialog open={openRegister} handleClose={handleCloseRegister} />
                <LoginDialog open={openDialog} handleClose={handleCloseDialog} />
                {statusRegister === "authenticated" && (
                    <Button onClick={onLogout} className="userOpcion">
                        Cerrar sesión
                    </Button>
                )}
            </Stack>
        </div>
    );

    return (
        <AppBar position="fixed" sx={{ top: 0, left: 0, right: 0, background: "linear-gradient(0deg,#141e1b -40.38%, #2b363b 100%)" }}>
            <Toolbar>
                {isMobile || isTablet ? (
                    <>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
                            {drawer}
                        </Drawer>
                        <div style={{ flexGrow: 1 }} />
                        <NavLink to="/">
                            <img src="/content/img/SC/newLogoSC.png" alt="Logo" style={{ height: "80px" }} />
                            {/* <img src="/content/img/SC/halloween/logoHalloween.svg" alt="Logo" style={{ height: "40px" }} /> */}
                        </NavLink>
                    </>
                ) : (
                    <>
                        <Stack direction={"row"} style={{ minWidth: "50%" }}>
                            <NavLink to="/">
                                <img src="/content/img/SC/newLogoSC.png" alt="Logo" style={{ height: "80px", marginRight: "150px", marginLeft: "41%" }} />
                                {/* <img src="/content/img/SC/halloween/logoHalloween.svg" alt="Logo" style={{ height: "60px", marginRight: "150px", marginLeft: "41%" }} /> */}
                            </NavLink>
                            <div style={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
                                {menuItems.map((menu, index) => (
                                    <div className="dropdown" key={index}>
                                        <NavLink to={menu.route} className="dropbtn" style={{ fontFamily: "FuturaP-ExtraBold", textTransform: "uppercase", color: "#fff", textDecoration: "none" }}>
                                            {menu.name}
                                        </NavLink>
                                        {menu.name === "Cartelera" && (
                                            <div className="dropdown-content menu-container">
                                                "
                                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} style={{ columnGap: "3%", marginLeft: "-20%" }}>
                                                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                                                        {/* Mostrar el preloader mientras se carga la imagen */}
                                                        {/* {!imageLoaded && <CircularProgress />}
                                                    {randomMovie && <img src={randomMovie.image} style={{ width: "204px", height: "247px", display: imageLoaded ? "block" : "none" }} onLoad={handleImageLoad} />}
                                                    {imageLoaded && (
                                                        <Button className="btn-menu" variant="contained" onClick={handleButtonClick}>
                                                            COMPRÁ TUS ENTRADAS
                                                        </Button>
                                                    )} */}
                                                        <Button onClick={handleButtonClick}>
                                                            <img src="/content/img/SC/kravenmenu.png" style={{ width: isSurface ? "150px" : "274px", height: isSurface ? "150px" : "247px" }} />
                                                        </Button>
                                                    </Stack>
                                                    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0}>
                                                        <NavLink to="/cartelera?seccion=1" className="title-option">
                                                        PELÍCULAS
                                                        </NavLink>
                                                        <Stack direction="row">
                                                            <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={-1} style={{ paddingLeft: "30px", minWidth: "321px" }}>
                                                                {menu.subItems.slice(0, 5).map(
                                                                    (subItem, subIndex) =>
                                                                        menu.name === "Cartelera" && (
                                                                            <a href="#" className="text-option" key={subIndex} onClick={() => getIdMovieInfo(subItem.id)}>
                                                                                {subItem.name}
                                                                            </a>
                                                                        ),
                                                                )}
                                                            </Stack>
                                                            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={-1} style={{ display: hasSubItems ? "flex" : "none", paddingLeft: "30px", minWidth: "321px" }}>
                                                                {menu.subItems.slice(5).map(
                                                                    (subItem, subIndex) =>
                                                                        menu.name === "Cartelera" && (
                                                                            <a href="#" className="text-option" key={subIndex} onClick={() => getIdMovieInfo(subItem.id)}>
                                                                                {subItem.name}
                                                                            </a>
                                                                        ),
                                                                )}
                                                                <NavLink to="/cartelera?seccion=1" className="text-option">
                                                                VER MÁS...
                                                                </NavLink>
                                                            </Stack>
                                                            <Stack style={{ paddingLeft: "15%" }} spacing={-1}>
                                                            <NavLink to="/cartelera" className="text-option">
                                                                    CARTELERA
                                                                </NavLink>

                                                                <NavLink to="/cartelera?seccion=2" className="text-option">
                                                                    PREVENTAS
                                                                </NavLink>

                                                                <NavLink to="/cartelera" className="text-option">
                                                                    PRÓXIMAMENTE
                                                                </NavLink>

                                                                <NavLink to="/confiteria" className="text-option">
                                                                    PROMOCIONALES
                                                                </NavLink>
                                                            </Stack>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </div>
                                        )}
                                        {menu.name === "Confitería" && (
                                            <div className="dropdown-content menu-container confi">
                                                <Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={2} style={{ columnGap: "3%", marginLeft: "-25%" }}>
                                                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                                                        <img src="/content/img/SC/confiSonic.png" style={{ width: isSurface ? "150px" : "274px", height: isSurface ? "150px" : "247px" }} />

                                                        <NavLink className="btn-menu" to="/confiteria">
                                                            ADQUIRIR
                                                        </NavLink>
                                                    </Stack>
                                                    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                                                        {step4.dataProducts
                                                            .filter((category) => category.id === 111)
                                                            .map((category) => (
                                                                <React.Fragment key={category.id}>
                                                                    <NavLink to="/confiteria" className="title-option">
                                                                        {category.title}
                                                                    </NavLink>
                                                                    <Stack style={{ paddingLeft: "30px" }}>
                                                                        {category.products.map((product) => (
                                                                            <NavLink key={product.id} to="/confiteria" className="text-option">
                                                                                {product.name}
                                                                            </NavLink>
                                                                        ))}
                                                                    </Stack>
                                                                </React.Fragment>
                                                            ))}

                                                        <NavLink to="/confiteria" className="title-option">
                                                            COMBOS
                                                        </NavLink>
                                                    </Stack>
                                                    <Divider orientation="vertical" flexItem style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }} />
                                                    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={3} style={{ marginTop: "-5px" }}>
                                                        <NavLink to="/confiteria" className="title-option">
                                                            COMBOS INFANTILES
                                                        </NavLink>

                                                        <NavLink to="/confiteria" className="title-option">
                                                            INDIVIDUALES
                                                        </NavLink>
                                                    </Stack>
                                                </Stack>
                                            </div>
                                        )}
                                        {menu.name === "Otros" && (
                                            <div className="dropdown-content menu-container otros">
                                                <Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={2} style={{ columnGap: "3%", marginLeft: "-25%" }}>
                                                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                                                        <img src="/content/img/SC/otrosSonic.png" style={{ width: isSurface ? "150px" : "274px", height: isSurface ? "150px" : "247px" }} />
                                                        <NavLink to="/confiteria" className="btn-menu">
                                                            CONOCER MÁS
                                                        </NavLink>
                                                    </Stack>
                                                    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={-1}>
                                                        <a href="#" className="title-option">
                                                            ACERCA DE
                                                        </a>
                                                        <Stack style={{ paddingLeft: "30px" }}>
                                                            <NavLink to="/nosotros" className="text-option">
                                                                NOSOTROS
                                                            </NavLink>

                                                            <NavLink to="/nosotros" className="text-option">
                                                                CALIFICA EL SERVICIO
                                                            </NavLink>
                                                        </Stack>
                                                    </Stack>
                                                    <Divider orientation="vertical" flexItem style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }} />
                                                    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={-1} style={{ marginTop: "-5px" }}>
                                                        <NavLink to="/legales" className="title-option">
                                                            LEGAL
                                                        </NavLink>
                                                        <Stack style={{ paddingLeft: "30px" }}>
                                                            <NavLink to="/legales" className="text-option">
                                                                TÉRMINOS Y CONDICIONES
                                                            </NavLink>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </Stack>
                        <Stack alignItems={"flex-end"} style={{ minWidth: "45%" }}>
                            <div className="dropdown">
                                <div className="dropbtn">
                                    <img src="/content/img/SC/usuario icono 1.svg" alt="usuario" />
                                    {/* <img src="/content/img/SC/HalloweenAvatar.svg" alt="usuario" style={{width:"42px", height:"46px"}} /> */}
                                </div>

                                <div className="dropdown-content menu-container usuario">
                                    <Stack alignItems={"flex-start"}>
                                        {statusRegister === "authenticated" && (
                                            <Button className="userOpcion">
                                                {name_user} {lasname_user}
                                            </Button>
                                        )}
                                        {statusRegister === "checking" && (
                                            <>
                                                <Button className="userOpcion" onClick={handleClickOpenRegister}>
                                                    Registro
                                                </Button>

                                                <Button className="userOpcion" onClick={handleClickOpenDialog}>
                                                    Iniciar Sesión
                                                </Button>
                                            </>
                                        )}
                                        <RegisterDialog open={openRegister} handleClose={handleCloseRegister} />
                                        <LoginDialog open={openDialog} handleClose={handleCloseDialog} />
                                        {statusRegister === "authenticated" && (
                                            <Button onClick={onLogout} className="userOpcion">
                                                Cerrar sesión
                                            </Button>
                                        )}
                                    </Stack>
                                </div>
                            </div>
                        </Stack>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
